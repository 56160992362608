import * as React from 'react'
import { graphql } from 'gatsby'
import './index.scss'
import Header from '../components/header/header'
import WithHeaderLayour from '../layouts/with-header'
import Footer from '../components/footer/footer'
import Hero from '../components/hero/hero'
import Quotation from '../components/quotation/quotation'
import SeloAbracam from '../components/selo-abracam/selo-abracam'

const IndexPage = ({ data }) => {
  const nomeDaEmpresa = data.contentfulGeral.nomeDaEmpresa
  const telefone = data.contentfulGeral.telefone

  return (
    <WithHeaderLayour>
      <Hero />
      <div className="container" style={{ padding: '70px 0' }}>
        <h2>Para você</h2>
        <div>
          A JMS oferece todo o suporte necessário para você realizar operações
          de câmbio
          <ul>
            <li>Câmbio e turismo</li>
            <li>Transferências internacionais</li>
            <li>Seguro-viagem</li>
            <li>Chip com cobertura internacional</li>
          </ul>
        </div>
        <h2>Para sua empresa</h2>
        <div>
          Uma parceria de sucesso para as operações de câmbio do seu negócio é a
          garantia de uma evolução ainda mais acelerada. Conte com a expertise
          da JMS para cuidar de todas as suas preocupações.
          <ul>
            <li>
              <strong>Câmbio comercial</strong> - Conte conosco para as
              operações de câmbio e análise dos processos de importação e
              exportação da sua empresa com segurança, transparência e
              agilidade.
            </li>
            <li>
              <strong>Câmbio financeiro</strong> - Realizamos suas operações de
              câmbio com segurança, agilidade e com atendimento personalizado.
            </li>
          </ul>
        </div>
      </div>
      <div style={{ backgroundColor: '#fc0', padding: '70px 0' }}>
        <div className="container">
          <h2>Parceria DHL</h2>
          <div>
            Através da parceria da JMS com a DHL, nossos clientes podem contar
            com uma ampla variedade de serviços expressos de documentos e
            encomendas, além de soluções de preparo e acompanhamento de seus
            envios, de acordo com sua necessidade.
          </div>
        </div>
      </div>
      <SeloAbracam />
      <Footer />
    </WithHeaderLayour>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query HomeQuery {
    contentfulGeral {
      nomeDaEmpresa
      telefone
    }
  }
`
